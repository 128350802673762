import gsap from "gsap";
let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {
   var mservDecArea = document.querySelector('.services-dec__magnet-area');
   var cursor = document.querySelector(".cursor-def");

   // 1. Set the function and variables
   function parallaxItBtn(e, target, movement = 1) {
      var boundingRect = mservDecArea.getBoundingClientRect();
      var relX = e.pageX - boundingRect.left;
      var relY = e.pageY - boundingRect.top;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      gsap.to(target, {
         x: (relX - boundingRect.width / 2) * movement,
         y: (relY - boundingRect.height / 2 - scrollTop) * movement,
         rotate: relX / 2,
         ease: "power2",
         duration: 0.6
      });
   }

   // 2. Call the function
   function callParallaxBtn(e) {
      parallaxItBtn(e, '.services-dec__magnet-content');
   }

   mservDecArea.addEventListener('mousemove', function (e) {
      callParallaxBtn(e);
   });

   mservDecArea.onmouseenter = function () {
      cursor.style.transform = "scale(0)";
   }

   mservDecArea.addEventListener('mouseleave', function (e) {
      gsap.to('.services-dec__magnet-content', {
         scale: 1,
         x: 0,
         y: 0,
         ease: "power1",
         duration: 1.4
      });
      cursor.style.transform = "scale(0.25)";
   });



   //mservDecArea.addEventListener('mousemove', function (e) {
   //   mservBallDots.forEach((mservBallDot) => {

   //      var servBallRectBall = mservBall.getBoundingClientRect();
   //      var servBallRectBallX = e.pageX - servBallRectBall.left;
   //      var servBallRectBallY = e.pageY - servBallRectBall.top;
   //      //var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

   //      gsap.to(mservBallDot, {
   //         x: servBallRectBallX,
   //         y: servBallRectBallY,
   //         //ease: "power2",
   //         //duration: 0.1
   //      });
   //   });
   //});

});