import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

mm.add("(min-width: 480px)", () => {
   gsap.set('.w-method__content', {
      yPercent: -50
   })

   const uncover = gsap.timeline({
      paused: true
   })

   uncover.to('.w-method__content', {
      yPercent: 0,
      ease: 'none'
   });

   ScrollTrigger.create({
      trigger: '.services',
      //scroller: scroller,
      start: 'bottom bottom',
      end: '+=100%',
      animation: uncover,
      scrub: true,
      //markers: true,
   });

});

gsap.to(".w-method__mem", {
   scale: 1,
   duration: 3,
   ease: "power3.out",
   scrollTrigger: {
      trigger: '.services',
      //scroller: scroller,
      start: '110% bottom',
      end: '+=30%',
      //scrub: true,
      //markers: true,
   }
});