import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//import gradient from "./bg-grad";

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();


mm.add("(min-width: 480px)", () => {
   /// BG gradient
   gsap.to(".bg", {
      //gradient: "linear-gradient(270deg, rgba(250, 250, 255, 1) 0%, rgba(250, 250, 255, 1) 56.25%, rgba(250, 250, 255, 1) 96.93%)",
      //duration: 3,
      opacity: 1,
      //background:"#FFFFFFFF",
      scrollTrigger: {
         //scroller: scroller, // neccessary setting for smooth-scrollbar on body
         trigger: ".bg-color-triger",
         start: "top bottom",
         end: "+=120",
         scrub: 1,
         //markers: true,
      }
   });
});