import gsap from "gsap";

let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {

   //var mArea = document.querySelector('#magnetic-area');
   var mAreaBoll = document.querySelector('#magnetic-area-ball');

   // 1. Set the function and variables
   //function parallaxItBtn(e, target, movement = 0.5) {
   //   var boundingRect = mArea.getBoundingClientRect();
   //   var relX = e.pageX - boundingRect.left;
   //   var relY = e.pageY - boundingRect.top;
   //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

   //   gsap.to(target, {
   //      x: (relX - boundingRect.width / 2) * movement,
   //      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
   //      ease: "power4",
   //      duration: 0.6
   //   });
   //}

   function parallaxItBall(e, target, movement = 0.62) {
      var boundingRect = mAreaBoll.getBoundingClientRect();
      var relX = e.pageX - boundingRect.left;
      var relY = e.pageY - boundingRect.top;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      gsap.to(target, {
         x: (relX - boundingRect.width / 2) * movement,
         y: (relY - boundingRect.height / 2 - scrollTop) * movement,
         ease: "power4",
         duration: 0.5
      });
   }

   // 2. Call the function
   //function callParallaxBtn(e) {
   //   parallaxItBtn(e, '#magnetic-content');
   //   parallaxItBtn(e, '.bg-dot-burger');
   //}

   function callParallaxBall(e) {
      parallaxItBall(e, '#magnetic-content-ball');
      parallaxItBall(e, '.bg-dot-ball');
   }

   //mArea.addEventListener('mousemove', function (e) {
   //   callParallaxBtn(e);
   //});
   mAreaBoll.addEventListener('mousemove', function (e) {
      callParallaxBall(e);
   });

   //mArea.addEventListener('mouseleave', function (e) {
   //   gsap.to('#magnetic-content', {
   //      scale: 1,
   //      x: 0,
   //      y: 0,
   //      ease: "power1",
   //      duration: 1.4
   //   });
   //   gsap.to('.bg-dot-burger', {
   //      x: 0,
   //      y: 0,
   //      ease: "power1",
   //      duration: .2
   //   });
   //});

   mAreaBoll.addEventListener('mouseleave', function (e) {
      gsap.to('#magnetic-content-ball', {
         scale: 1,
         x: 0,
         y: 0,
         ease: "power1",
         duration: .6
      });
      gsap.to('.bg-dot-ball', {
         x: 0,
         y: 0,
         ease: "power1",
         duration: .2
      });
   });

});