import Matter from "matter-js";

setTimeout(function () {
   var Engine = Matter.Engine,
      //Render = Matter.Render,
      World = Matter.World,
      Bodies = Matter.Bodies;
   const Runner = Matter.Runner;


   const tagsItem = document.querySelectorAll("#tags-list .item");
   const tagsItemSpan = document.querySelectorAll("#tags-list .item span");

   const tagsBox = document.querySelector("#tags");
   const tagsBoxParam = tagsBox.getBoundingClientRect();
   var tagsBoxX = tagsBox.offsetLeft; // левый отступ эл-та от родителя
   var tagsBoxY = tagsBox.offsetTop; // верхний отступ эл-та от родителя

   const tagsBtnBox = document.querySelector("#tags-list .btn");

   const stack = Matter.Composites.stack(
      // xx, yy, columns, rows, columnGap, rowGap, cb
      0, 0, tagsItem.length, 1, 0, 0,
      (xx, yy, i) => {
         const {
            width,
            height
         } = tagsItem[i].getBoundingClientRect();
         //const width = tagsItem[i].offsetWidth;
         //const height = tagsItem[i].offsetHeight;
         const x = Math.floor(Math.random() * (tagsBoxParam.width - 200));
         const y = Math.floor(Math.random() * -1500);
         return Matter.Bodies.rectangle(x, y, width, height, {
            chamfer: {
               radius: height / 2
            },
            //friction: 0.05,
            //frictionAir: 0.0005,
            restitution: 0.8,
         });
      }
   );



   var engine = Engine.create();

   //var render = Render.create({
   //   element: document.getElementById("tags"),
   //   engine: engine,
   //   options: {
   //      width: tagsBoxParam.width,
   //      height: tagsBoxParam.height,
   //      wireframes: false
   //   }
   //});



   var ground = Matter.Bodies.rectangle((tagsBoxParam.width / 2) + tagsBoxX, tagsBoxParam.height + 1, tagsBoxParam.width, 2, {
      isStatic: true
   });
   var leftWall = Matter.Bodies.rectangle(0, (tagsBoxParam.height / 2) + tagsBoxY, 1, tagsBoxParam.height * 50, {
      isStatic: true
   });
   var rightWall = Matter.Bodies.rectangle(tagsBoxParam.width, (tagsBoxParam.height / 2) + tagsBoxY, 1, tagsBoxParam.height * 50, {
      isStatic: true
   });

   var tagsBtnParams = tagsBtnBox.getBoundingClientRect();
   var tagsBtnY = tagsBtnBox.offsetTop;
   var tagsBtnX = tagsBtnBox.offsetLeft;
   var tagsBtn = Matter.Bodies.rectangle((tagsBtnParams.width / 2) + tagsBtnX, ((tagsBtnParams.height - 2) / 2) + tagsBtnY + 1, tagsBtnParams.width, tagsBtnParams.height, {
      chamfer: {
         radius: tagsBtnParams.height / 2
      },
      isStatic: true
   });



   const mouseConstraint = Matter.MouseConstraint.create(
      engine, {
         element: document.querySelector("#tags")
      }
   );
   mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
   mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);

   engine.gravity.y = 0.7;
   World.add(engine.world, [ground, leftWall, rightWall, stack, tagsBtn, mouseConstraint]);


   const observer = new IntersectionObserver(entries => {
      // перебор записей
      entries.forEach(entry => {
         // если элемент появился
         if (entry.isIntersecting) {
            observer.unobserve(entry.target);
            // run the engine
            //Engine.run(engine);
            Runner.run(engine);
            // run the renderer
            //Render.run(render);
            stack.bodies.forEach((block, i) => {
               const item = tagsItem[i];
               const itemSpan = tagsItemSpan[i];
               const itemHeightPos = item.offsetHeight / 2;
               (function update() {
                  requestAnimationFrame(update);
                  const {
                     x,
                     y
                  } = block.vertices[0];
                  item.style.transform = `translate(${x}px, ${y-itemHeightPos}px)`;
                  itemSpan.style.transform = `rotate(${block.angle}rad)`;
               })();
            });
         }
      });
   });
   observer.observe(document.querySelector('.tags-trigger'));
}, 1900);