import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

mm.add("(min-width: 480px)", () => {
   //const vh = (coef) => window.innerHeight * (coef / 100);
   const vw = (coef) => window.innerWidth * (coef / 100);

   var katPinEnd = document.querySelector(".kat-pin-end");
   let katPinEndInfo = katPinEnd.getBoundingClientRect();
   let endKatPin = vw((katPinEndInfo.top - 50) / (window.innerWidth / 100));

   //console.log(katPinEndInfo.top);
   //console.log(endKatPin);

   gsap.defaults({
      overwrite: 'auto'
   });

   //gsap.set(".kat", { xPercent: -50, yPercent: -50 });

   ScrollTrigger.create({
      trigger: ".kat-pin-trigger",
      //scroller: scroller, // neccessary setting for smooth-scrollbar on body
      //pinType: 'transform', // neccessary setting for smooth-scrollbar on body
      pin: ".kat-pined",
      ease: "power3.out",
      start: vw(16) + ' top',
      end: "+=" + endKatPin,
      scrub: true,
      //markers: true,
   });
   ScrollTrigger.create({
      trigger: ".kat-bg-decor",
      //scroller: scroller, // neccessary setting for smooth-scrollbar on body
      //pinType: 'transform', // neccessary setting for smooth-scrollbar on body
      pin: ".kat-bg-decor",
      ease: "power3.out",
      start: "top 30%",
      end: "+=200",
      scrub: true,
      //markers: true,
   });

});