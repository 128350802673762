import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

const tl = gsap.timeline({
   scrollTrigger: {
      trigger: "#first-screen",
      start: "top top",
      end: "bottom top",
      scrub: true,
      //markers: true,
   }
});

mm.add("(min-width: 480px)", () => {
   gsap.utils.toArray(".parallaxed").forEach(layer => {
      const depth = layer.dataset.depth;
      const movement = -(layer.offsetHeight * depth)
      tl.to(layer, {
         y: movement,
         ease: "none"
      }, 0.1)
   });
});
mm.add("(max-width: 480px)", () => {
   gsap.utils.toArray(".parallaxed").forEach(layer => {
      const depth = layer.dataset.mobdepth;
      const movement = -(layer.offsetHeight * depth)
      tl.to(layer, {
         y: movement,
         ease: "none"
      }, 0.1)
   });
});