import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Draggable from "gsap/Draggable";
import Observer from "gsap/Observer";
//import InertiaPlugin from "gsap/InertiaPlugin";
import {
   tns
} from "ppn-tiny-slider/src/tiny-slider"



gsap.registerPlugin(ScrollTrigger, Draggable, Observer);

let mm = gsap.matchMedia();
gsap.set(".carusel__items-2", {
   clearProps: "all"
});
gsap.set(".carusel__item-loop-2", {
   clearProps: "all"
});
gsap.set(".carusel__items-1", {
   clearProps: "all"
});
gsap.set(".carusel__item-loop-1", {
   clearProps: "all"
});
gsap.set(".w-method__items", {
   clearProps: "all"
});
gsap.set(".w-method__item", {
   clearProps: "all"
});
gsap.set(".brands-line", {
   clearProps: "all"
});
gsap.set(".brands-item", {
   clearProps: "all"
});


mm.add("(max-width: 480px)", () => {
   requestAnimationFrame(slider_WMethod_mob);

   function slider_WMethod_mob() {
      var slider = tns({
         controls: false,
         nav: false,
         autoWidth: true,
         //gutter: 30,
         container: '.w-method__items-inner',
         items: 1,
         slideBy: 'page',
         autoplay: false,
         mouseDrag: true,
         swipeAngle: 30,
         speed: 720,
         preventScrollOnTouch: 'auto'
      });
   }

   requestAnimationFrame(slider_reviews_mob);

   function slider_reviews_mob() {
      var slider2 = tns({
         controls: false,
         nav: false,
         autoWidth: true,
         //gutter: 30,
         container: '.carusel__items-1',
         items: 1,
         slideBy: 'page',
         autoplay: false,
         mouseDrag: true,
         swipeAngle: 30,
         speed: 720,
         lazyload: true,
         preventScrollOnTouch: 'auto'
      });
   }

});


//import horizontalLoop from 'loop.js';

//text-lent
//const wrapper = document.querySelector(".w-method__items-inner");
//const boxes = gsap.utils.toArray(".w-method__item");
//const loop = horizontalLoop(boxes, {
//      paused: true,
//      repeat: -1,
//      draggable: true,
//      center: true,
//      speed: 0.3
//   }),
//   clamp = gsap.utils.clamp(-60, 60),
//   isOver, reversedOnPause;

//mm.add("(min-width: 480px)", () => {
//   gsap.to(".w-method", {
//      scrollTrigger: {
//         trigger: ".w-method",
//         start: "top bottom",
//         end: "bottom top",
//         onEnter: elements => {
//            loop.play();
//         },
//         onLeave: elements => {
//            loop.pause();
//         },
//         onEnterBack: elements => {
//            loop.play();
//         },
//         onLeaveBack: elements => {
//            loop.pause();
//         },
//         onUpdate: function (self) {
//            self.direction === -1 ? loop.timeScale(-1) : loop.timeScale(1);
//         }
//      }
//   });
//});

//const wMethodItems = document.querySelector(".w-method__items");
//wMethodItems.addEventListener("mouseenter", () => {
//   loop.pause();
//});
//wMethodItems.addEventListener("mouseleave", () => {
//   loop.play();
//});

//Observer.create({
//   target: document.scrollingElement,
//   type: "scroll,wheel",
//   onChangeY: self => {
//      loop.timeScale(clamp(self.velocityY * 0.003));
//      if (isOver) {
//         gsap.to(loop, {
//            timeScale: 0,
//            duration: 1,
//            overwrite: true
//         });
//      }
//   }
//});
setTimeout(function () {
   mm.add("(min-width: 480px)", () => {
      function sliderWMethod() {
         gsap.utils.toArray('.w-method__items').forEach((container, i) => {
            let tl = horizontalLoop(container.querySelectorAll(".w-method__item"), {
                  paused: true,
                  repeat: -1,
                  draggable: true,
                  center: true,
                  speed: 0.3
               }),
               clamp = gsap.utils.clamp(-10, 10),
               isOver, reversedOnPause;

            //Observer.create({
            //   target: document.scrollingElement,
            //   type: "scroll,wheel",
            //   onChangeY: self => {
            //      tl.timeScale(clamp(self.velocityY * 0.003));
            //      if (isOver) {
            //         gsap.to(tl, {
            //            timeScale: 0,
            //            duration: 1,
            //            overwrite: true
            //         });
            //      }
            //   }
            //});
            let observer = ScrollTrigger.observe({
               target: document.scrollingElement,
               type: "scroll,wheel",
               wheelSpeed: 0.5,
               tolerance: 12,
               onChangeY: self => {
                  //console.log(clamp(self.velocityY * 0.003));
                  gsap.to(tl, {
                     timeScale: clamp(self.velocityY * 0.003),
                     duration: 0.4,
                     ease: "power1.out",
                  });

                  if (isOver) {
                     gsap.to(tl, {
                        timeScale: 0,
                        duration: 3,
                        ease: "power1.out",
                        overwrite: true
                     });
                  }
               }
            });
            observer.disable();
            gsap.to(tl, {
               scrollTrigger: {
                  trigger: ".w-method",
                  start: "top bottom",
                  end: "bottom top",
                  onEnter: elements => {
                     tl.play();
                     observer.enable();
                  },
                  onLeave: elements => {
                     tl.pause();
                     observer.disable();
                  },
                  onEnterBack: elements => {
                     tl.play();
                     observer.enable();
                  },
                  onLeaveBack: elements => {
                     tl.pause();
                     observer.disable();
                  },
                  //onUpdate: function (self) {
                  //   self.direction === -1 ? tl.timeScale(-1) : tl.timeScale(1);
                  //}
               }
            });

            container.addEventListener("mouseenter", () => {
               reversedOnPause = tl.timeScale() < 0;
               isOver = true;
               gsap.to(tl, {
                  timeScale: 0,
                  duration: 3,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();

               //container.classList.add("paused");
            });
            container.addEventListener("mouseleave", () => {
               isOver = false;
               gsap.to(tl, {
                  timeScale: reversedOnPause ? -1 : 1,
                  duration: 2,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();
               //container.classList.remove("paused");
            });
         });
      }
      sliderWMethod();

      return () => {
         gsap.set(".w-method__items", {
            clearProps: "all"
         });
         gsap.set(".w-method__item", {
            clearProps: "all"
         });
      };
   });
}, 8000);
setTimeout(function () {
   mm.add("(min-width: 480px)", () => {
      function sliderReviews_1() {
         gsap.utils.toArray('.carusel__items-1').forEach((container, i) => {
            let tl_2 = horizontalLoop(container.querySelectorAll(".carusel__item-loop-1"), {
                  paused: true,
                  repeat: -1,
                  draggable: true,
                  center: true,
                  speed: 0.3
               }),
               clamp = gsap.utils.clamp(-8, 8),
               isOver, reversedOnPause;

            let observer = ScrollTrigger.observe({
               target: document.scrollingElement,
               type: "scroll,wheel",
               wheelSpeed: 0.5,
               tolerance: 12,
               onChangeY: self => {
                  //console.log(clamp(self.velocityY * 0.003));
                  gsap.to(tl_2, {
                     timeScale: clamp(self.velocityY * 0.003),
                     duration: 0.4,
                     ease: "power1.out",
                  });

                  if (isOver) {
                     gsap.to(tl_2, {
                        timeScale: 0,
                        duration: 3,
                        ease: "power1.out",
                        overwrite: true
                     });
                  }
               }
            });
            observer.disable();
            gsap.to(tl_2, {
               scrollTrigger: {
                  trigger: ".reviews-content",
                  start: "top bottom",
                  end: "bottom top",
                  onEnter: elements => {
                     tl_2.play();
                     observer.enable();
                  },
                  onLeave: elements => {
                     tl_2.pause();
                     observer.disable();
                  },
                  onEnterBack: elements => {
                     tl_2.play();
                     observer.enable();
                  },
                  onLeaveBack: elements => {
                     tl_2.pause();
                     observer.disable();
                  }
               }
            });

            container.addEventListener("mouseenter", () => {
               reversedOnPause = tl_2.timeScale() < 0;
               isOver = true;
               gsap.to(tl_2, {
                  timeScale: 0,
                  duration: 3,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();

               //container.classList.add("paused");
            });
            container.addEventListener("mouseleave", () => {
               isOver = false;
               gsap.to(tl_2, {
                  timeScale: reversedOnPause ? -1 : 1,
                  duration: 2,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();
               //container.classList.remove("paused");
            });
         });
      }
      sliderReviews_1();
      return () => {
         gsap.set(".carusel__items-1", {
            clearProps: "all"
         });
         gsap.set(".carusel__item-loop-1", {
            clearProps: "all"
         });
      };
   });
}, 8000);
//const carusel = gsap.utils.toArray(".carusel__item-loop-1");
//const caruselLoop = horizontalLoop(carusel, {
//   paused: true,
//   repeat: -1,
//   draggable: true,
//   center: true,
//   speed: 0.2
//});

//const caruselItems1 = document.querySelector(".carusel__items-1");
//caruselItems1.addEventListener("mouseenter", () => {
//   caruselLoop.pause();
//});
//caruselItems1.addEventListener("mouseleave", () => {
//   caruselLoop.play();
//});
setTimeout(function () {
   mm.add("(min-width: 480px)", () => {
      function sliderReviews_2() {
         gsap.utils.toArray('.carusel__items-2').forEach((container, i) => {
            let tl_3 = horizontalLoop(container.querySelectorAll(".carusel__item-loop-2"), {
                  paused: true,
                  repeat: -1,
                  draggable: true,
                  center: true,
                  speed: 0.2
               }),
               clamp = gsap.utils.clamp(-6, 6),
               isOver, reversedOnPause;

            let observer = ScrollTrigger.observe({
               target: document.scrollingElement,
               type: "scroll,wheel",
               wheelSpeed: 0.7,
               tolerance: 12,
               onChangeY: self => {
                  //console.log(clamp(self.velocityY * 0.003));
                  gsap.to(tl_3, {
                     timeScale: clamp(self.velocityY * 0.003),
                     duration: 0.5,
                     ease: "power1.out",
                  });

                  if (isOver) {
                     gsap.to(tl_3, {
                        timeScale: 0,
                        duration: 3,
                        ease: "power1.out",
                        overwrite: true
                     });
                  }
               }
            });
            observer.disable();
            gsap.to(tl_3, {
               scrollTrigger: {
                  trigger: ".reviews-content",
                  start: "top bottom",
                  end: "bottom top",
                  onEnter: elements => {
                     tl_3.play();
                     observer.enable();
                  },
                  onLeave: elements => {
                     tl_3.pause();
                     observer.disable();
                  },
                  onEnterBack: elements => {
                     tl_3.play();
                     observer.enable();
                  },
                  onLeaveBack: elements => {
                     tl_3.pause();
                     observer.disable();
                  }
               }
            });

            container.addEventListener("mouseenter", () => {
               reversedOnPause = tl_3.timeScale() < 0;
               isOver = true;
               gsap.to(tl_3, {
                  timeScale: 0,
                  duration: 3,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();

               //container.classList.add("paused");
            });
            container.addEventListener("mouseleave", () => {
               isOver = false;
               gsap.to(tl_3, {
                  timeScale: reversedOnPause ? -1 : 1,
                  duration: 2,
                  ease: "power1.out",
                  overwrite: true
               });
               observer.enable();
               //container.classList.remove("paused");
            });
         });
      }
      sliderReviews_2();
      return () => {
         gsap.set(".carusel__items-2", {
            clearProps: "all"
         });
         gsap.set(".carusel__item-loop-2", {
            clearProps: "all"
         });
      };
   });
}, 8000);
//const carusel2 = gsap.utils.toArray(".carusel__item-loop-2");
//const caruselLoop2 = horizontalLoop(carusel2, {
//   paused: true,
//   repeat: -1,
//   draggable: true,
//   center: true,
//   speed: 0.3
//});

//const caruselItems2 = document.querySelector(".carusel__items-2");
//caruselItems2.addEventListener("mouseenter", () => {
//   caruselLoop2.pause();
//});
//caruselItems2.addEventListener("mouseleave", () => {
//   caruselLoop2.play();
//});

//mm.add("(min-width: 480px)", () => {
//   gsap.to(".carusel", {
//      scrollTrigger: {
//         trigger: ".carusel",
//         start: "top bottom",
//         end: "bottom top",
//         onEnter: elements => {
//            //caruselLoop.play();
//            caruselLoop2.play();
//         },
//         onLeave: elements => {
//            //caruselLoop.pause();
//            caruselLoop2.pause();
//         },
//         onEnterBack: elements => {
//            //caruselLoop.play();
//            caruselLoop2.play();
//         },
//         onLeaveBack: elements => {
//            //caruselLoop.pause();
//            caruselLoop2.pause();
//         },
//         onUpdate: function (self) {
//            //self.direction === -1 ? caruselLoop.timeScale(-1) : caruselLoop.timeScale(1);
//            self.direction === -1 ? caruselLoop2.timeScale(-1) : caruselLoop2.timeScale(1);
//         }
//      }
//   });
//});
setTimeout(function () {
   function sliderBrands() {
      gsap.utils.toArray('.brands-line').forEach((container, i) => {
         let tl_brands = horizontalLoop(container.querySelectorAll(".brands-item"), {
               paused: true,
               repeat: -1,
               draggable: true,
               center: true,
               speed: 0.3
            }),
            clamp = gsap.utils.clamp(-7, 7),
            isOver, reversedOnPause;

         let observer = ScrollTrigger.observe({
            target: document.scrollingElement,
            type: "scroll,wheel",
            wheelSpeed: 0.5,
            tolerance: 10,
            onChangeY: self => {
               //console.log(clamp(self.velocityY * 0.003));
               gsap.to(tl_brands, {
                  timeScale: clamp(self.velocityY * 0.003),
                  duration: 0.7,
                  ease: "power1.out",
               });

               if (isOver) {
                  gsap.to(tl_brands, {
                     timeScale: 0,
                     duration: 3,
                     ease: "power1.out",
                     overwrite: true
                  });
               }
            }
         });
         observer.disable();
         //mm.add("(min-width: 480px)", () => {
         gsap.to(tl_brands, {
            scrollTrigger: {
               trigger: ".reviews-content",
               start: "top bottom",
               end: "bottom top",
               onEnter: elements => {
                  tl_brands.play();
                  observer.enable();
               },
               onLeave: elements => {
                  tl_brands.pause();
                  observer.disable();
               },
               onEnterBack: elements => {
                  tl_brands.play();
                  observer.enable();
               },
               onLeaveBack: elements => {
                  tl_brands.pause();
                  observer.disable();
               }
            }
         });
         //});

         container.addEventListener("mouseenter", () => {
            reversedOnPause = tl_brands.timeScale() < 0;
            isOver = true;
            gsap.to(tl_brands, {
               timeScale: 0,
               duration: 3,
               ease: "power1.out",
               overwrite: true
            });
            observer.enable();

            //container.classList.add("paused");
         });
         container.addEventListener("mouseleave", () => {
            isOver = false;
            gsap.to(tl_brands, {
               timeScale: reversedOnPause ? -1 : 1,
               duration: 2,
               ease: "power1.out",
               overwrite: true
            });
            observer.enable();
            //container.classList.remove("paused");
         });
      });
   }
   sliderBrands();
}, 2000);
//const brands = gsap.utils.toArray(".brands-item");
//const brandsLoop = horizontalLoop(brands, {
//   paused: true,
//   repeat: -1,
//   //  draggable: true,
//   center: true,
//   speed: 1
//});

//gsap.to(".brands", {
//   scrollTrigger: {
//      trigger: ".brands",
//      start: "top bottom",
//      end: "+100vh",
//      onEnter: elements => {
//         brandsLoop.play();
//      },
//      onLeave: elements => {
//         brandsLoop.pause();
//      },
//      onEnterBack: elements => {
//         brandsLoop.play();
//      },
//      onLeaveBack: elements => {
//         brandsLoop.pause();
//      },
//      onUpdate: function (self) {
//         self.direction === -1 ? brandsLoop.timeScale(-1) : brandsLoop.timeScale(1);
//      }
//   }
//});


/*
This helper function makes a group of elements animate along the x-axis in a seamless, responsive loop.

Features:
 - Uses xPercent so that even if the widths change (like if the window gets resized), it should still work in most cases.
 - When each item animates to the left or right enough, it will loop back to the other side
 - Optionally pass in a config object with values like draggable: true, speed (default: 1, which travels at roughly 100 pixels per second), paused (boolean), repeat, reversed, and paddingRight.
 - The returned timeline will have the following methods added to it:
   - next() - animates to the next element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
   - previous() - animates to the previous element using a timeline.tweenTo() which it returns. You can pass in a vars object to control duration, easing, etc.
   - toIndex() - pass in a zero-based index value of the element that it should animate to, and optionally pass in a vars object to control duration, easing, etc. Always goes in the shortest direction
   - current() - returns the current index (if an animation is in-progress, it reflects the final index)
   - times - an Array of the times on the timeline where each element hits the "starting" spot. There's also a label added accordingly, so "label1" is when the 2nd element reaches the start.
 */
function horizontalLoop(items, config) {
   items = gsap.utils.toArray(items);
   config = config || {};
   let tl = gsap.timeline({
         repeat: config.repeat,
         paused: config.paused,
         defaults: {
            ease: "none"
         },
         onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
      }),
      length = items.length,
      startX = items[0].offsetLeft,
      times = [],
      widths = [],
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 100,
      snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
      populateWidths = () => items.forEach((el, i) => {
         widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
         xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / widths[i] * 100 + gsap.getProperty(el, "xPercent"));
      }),
      getTotalWidth = () => items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0),
      totalWidth, curX, distanceToStart, distanceToLoop, item, i;
   populateWidths();
   gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
      xPercent: i => xPercents[i]
   });
   gsap.set(items, {
      x: 0
   });
   totalWidth = getTotalWidth();
   for (i = 0; i < length; i++) {
      item = items[i];
      curX = xPercents[i] / 100 * widths[i];
      distanceToStart = item.offsetLeft + curX - startX;
      distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
      tl.to(item, {
            xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
            duration: distanceToLoop / pixelsPerSecond
         }, 0)
         .fromTo(item, {
            xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)
         }, {
            xPercent: xPercents[i],
            duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
            immediateRender: false
         }, distanceToLoop / pixelsPerSecond)
         .add("label" + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
   }

   function toIndex(index, vars) {
      vars = vars || {};
      (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
      let newIndex = gsap.utils.wrap(0, length, index),
         time = times[newIndex];
      if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
         vars.modifiers = {
            time: gsap.utils.wrap(0, tl.duration())
         };
         time += tl.duration() * (index > curIndex ? 1 : -1);
      }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
   }
   tl.next = vars => toIndex(curIndex + 1, vars);
   tl.previous = vars => toIndex(curIndex - 1, vars);
   tl.current = () => curIndex;
   tl.toIndex = (index, vars) => toIndex(index, vars);
   tl.updateIndex = () => curIndex = Math.round(tl.progress() * items.length);
   tl.times = times;
   tl.progress(1, true).progress(0, true); // pre-render for performance
   if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
   }
   if (config.draggable && typeof (Draggable) === "function") {
      let proxy = document.createElement("div"),
         wrap = gsap.utils.wrap(0, 1),
         ratio, startProgress, draggable, dragSnap, roundFactor,
         align = () => tl.progress(wrap(startProgress + (draggable.startX - draggable.x) * ratio)),
         syncIndex = () => tl.updateIndex();
      //typeof (InertiaPlugin) === "undefined" && console.warn("InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club");
      draggable = Draggable.create(proxy, {
         trigger: items[0].parentNode,
         type: "x",
         onPress() {
            startProgress = tl.progress();
            tl.progress(0);
            populateWidths();
            totalWidth = getTotalWidth();
            ratio = 1 / totalWidth;
            dragSnap = totalWidth / items.length;
            roundFactor = Math.pow(10, ((dragSnap + "").split(".")[1] || "").length);
            tl.progress(startProgress);
         },
         onDrag: align,
         onThrowUpdate: align,
         inertia: true,
         snap: value => {
            let n = Math.round(parseFloat(value) / dragSnap) * dragSnap * roundFactor;
            return (n - n % 1) / roundFactor;
         },
         onRelease: syncIndex,
         onThrowComplete: () => gsap.set(proxy, {
            x: 0
         }) && syncIndex()
      })[0];

      tl.draggable = draggable;
   }
   return tl;
}


//new
//function horizontalLoop(items, config) {
//   let timeline;
//   items = gsap.utils.toArray(items);
//   config = config || {};
//   gsap.context(() => { // use a context so that if this is called from within another context or a gsap.matchMedia(), we can perform proper cleanup like the "resize" event handler on the window
//      let onChange = config.onChange,
//         lastIndex = 0,
//         tl = gsap.timeline({
//            repeat: config.repeat,
//            onUpdate: onChange && function () {
//               let i = tl.closestIndex();
//               if (lastIndex !== i) {
//                  lastIndex = i;
//                  onChange(items[i], i);
//               }
//            },
//            paused: config.paused,
//            defaults: {
//               ease: "none"
//            },
//            onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
//         }),
//         length = items.length,
//         startX = items[0].offsetLeft,
//         times = [],
//         widths = [],
//         spaceBefore = [],
//         xPercents = [],
//         curIndex = 0,
//         indexIsDirty = false,
//         center = config.center,
//         pixelsPerSecond = (config.speed || 1) * 100,
//         snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
//         timeOffset = 0,
//         container = center === true ? items[0].parentNode : gsap.utils.toArray(center)[0] || items[0].parentNode,
//         totalWidth,
//         getTotalWidth = () => items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + spaceBefore[0] + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0),
//         populateWidths = () => {
//            let b1 = container.getBoundingClientRect(),
//               b2;
//            items.forEach((el, i) => {
//               widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
//               xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / widths[i] * 100 + gsap.getProperty(el, "xPercent"));
//               b2 = el.getBoundingClientRect();
//               spaceBefore[i] = b2.left - (i ? b1.right : b1.left);
//               b1 = b2;
//            });
//            gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
//               xPercent: i => xPercents[i]
//            });
//            totalWidth = getTotalWidth();
//         },
//         timeWrap,
//         populateOffsets = () => {
//            timeOffset = center ? tl.duration() * (container.offsetWidth / 2) / totalWidth : 0;
//            center && times.forEach((t, i) => {
//               times[i] = timeWrap(tl.labels["label" + i] + tl.duration() * widths[i] / 2 / totalWidth - timeOffset);
//            });
//         },
//         getClosest = (values, value, wrap) => {
//            let i = values.length,
//               closest = 1e10,
//               index = 0,
//               d;
//            while (i--) {
//               d = Math.abs(values[i] - value);
//               if (d > wrap / 2) {
//                  d = wrap - d;
//               }
//               if (d < closest) {
//                  closest = d;
//                  index = i;
//               }
//            }
//            return index;
//         },
//         populateTimeline = () => {
//            let i, item, curX, distanceToStart, distanceToLoop;
//            tl.clear();
//            for (i = 0; i < length; i++) {
//               item = items[i];
//               curX = xPercents[i] / 100 * widths[i];
//               distanceToStart = item.offsetLeft + curX - startX + spaceBefore[0];
//               distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
//               tl.to(item, {
//                     xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
//                     duration: distanceToLoop / pixelsPerSecond
//                  }, 0)
//                  .fromTo(item, {
//                     xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)
//                  }, {
//                     xPercent: xPercents[i],
//                     duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
//                     immediateRender: false
//                  }, distanceToLoop / pixelsPerSecond)
//                  .add("label" + i, distanceToStart / pixelsPerSecond);
//               times[i] = distanceToStart / pixelsPerSecond;
//            }
//            timeWrap = gsap.utils.wrap(0, tl.duration());
//         },
//         refresh = (deep) => {
//            let progress = tl.progress();
//            tl.progress(0, true);
//            populateWidths();
//            deep && populateTimeline();
//            populateOffsets();
//            deep && tl.draggable ? tl.time(times[curIndex], true) : tl.progress(progress, true);
//         },
//         onResize = () => refresh(true),
//         proxy;
//      gsap.set(items, {
//         x: 0
//      });
//      populateWidths();
//      populateTimeline();
//      populateOffsets();
//      window.addEventListener("resize", onResize);

//      function toIndex(index, vars) {
//         vars = vars || {};
//         (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
//         let newIndex = gsap.utils.wrap(0, length, index),
//            time = times[newIndex];
//         if (time > tl.time() !== index > curIndex && index !== curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
//            time += tl.duration() * (index > curIndex ? 1 : -1);
//         }
//         if (time < 0 || time > tl.duration()) {
//            vars.modifiers = {
//               time: timeWrap
//            };
//         }
//         curIndex = newIndex;
//         vars.overwrite = true;
//         gsap.killTweensOf(proxy);
//         return vars.duration === 0 ? tl.time(timeWrap(time)) : tl.tweenTo(time, vars);
//      }
//      tl.toIndex = (index, vars) => toIndex(index, vars);
//      tl.closestIndex = setCurrent => {
//         let index = getClosest(times, tl.time(), tl.duration());
//         if (setCurrent) {
//            curIndex = index;
//            indexIsDirty = false;
//         }
//         return index;
//      };
//      tl.current = () => indexIsDirty ? tl.closestIndex(true) : curIndex;
//      tl.next = vars => toIndex(tl.current() + 1, vars);
//      tl.previous = vars => toIndex(tl.current() - 1, vars);
//      tl.times = times;
//      tl.progress(1, true).progress(0, true); // pre-render for performance
//      if (config.reversed) {
//         tl.vars.onReverseComplete();
//         tl.reverse();
//      }
//      if (config.draggable && typeof (Draggable) === "function") {
//         proxy = document.createElement("div")
//         let wrap = gsap.utils.wrap(0, 1),
//            ratio, startProgress, draggable, dragSnap, lastSnap, initChangeX, wasPlaying,
//            align = () => tl.progress(wrap(startProgress + (draggable.startX - draggable.x) * ratio)),
//            syncIndex = () => tl.closestIndex(true);
//         //typeof (InertiaPlugin) === "undefined" && console.warn("InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club");
//         draggable = Draggable.create(proxy, {
//            trigger: items[0].parentNode,
//            type: "x",
//            onPressInit() {
//               let x = this.x;
//               gsap.killTweensOf(tl);
//               wasPlaying = !tl.paused();
//               tl.pause();
//               startProgress = tl.progress();
//               refresh();
//               ratio = 1 / totalWidth;
//               initChangeX = (startProgress / -ratio) - x;
//               gsap.set(proxy, {
//                  x: startProgress / -ratio
//               });
//            },
//            onDrag: align,
//            onThrowUpdate: align,
//            overshootTolerance: 0,
//            inertia: true,
//            snap(value) {
//               //note: if the user presses and releases in the middle of a throw, due to the sudden correction of proxy.x in the onPressInit(), the velocity could be very large, throwing off the snap. So sense that condition and adjust for it. We also need to set overshootTolerance to 0 to prevent the inertia from causing it to shoot past and come back
//               if (Math.abs(startProgress / -ratio - this.x) < 10) {
//                  return lastSnap + initChangeX
//               }
//               let time = -(value * ratio) * tl.duration(),
//                  wrappedTime = timeWrap(time),
//                  snapTime = times[getClosest(times, wrappedTime, tl.duration())],
//                  dif = snapTime - wrappedTime;
//               Math.abs(dif) > tl.duration() / 2 && (dif += dif < 0 ? tl.duration() : -tl.duration());
//               lastSnap = (time + dif) / tl.duration() / -ratio;
//               return lastSnap;
//            },
//            onRelease() {
//               syncIndex();
//               draggable.isThrowing && (indexIsDirty = true);
//            },
//            onThrowComplete: () => {
//               syncIndex();
//               wasPlaying && tl.play();
//            }
//         })[0];
//         tl.draggable = draggable;
//      }
//      tl.closestIndex(true);
//      lastIndex = curIndex;
//      onChange && onChange(items[curIndex], curIndex);
//      timeline = tl;
//      return () => window.removeEventListener("resize", onResize); // cleanup
//   });
//   return timeline;
//}

//var slideDelay = 1.5;
//var slideDuration = 1;
//var slides = document.querySelectorAll(".w-method__item");
//var numSlides = slides.length;

//gsap.set(slides, { xPercent: i => i * 100 });

//var wrap = gsap.utils.wrap(-100, (numSlides - 1) * 100);
//var wrapProgress = gsap.utils.wrap(0, 1);
//var timer = gsap.delayedCall(slideDelay, autoPlay).pause();
//var proxy = document.createElement("div");
//var slideWidth = 0;
//var wrapWidth = 0;
//var animation = gsap.timeline({repeat:-1});
//resize();

//var draggable = new Draggable(proxy, {
//  trigger: ".w-method__items",
//  type: "x",
////  inertia: true,
//  onPressInit: function() {
//    animation.pause();
//    timer.pause();
//    updateProgress();
//  },
//  snap: {
//    x: value => gsap.utils.snap(slideWidth, value)
//  },
//  onDrag: updateProgress,
//  onThrowUpdate: updateProgress,
//  onThrowComplete: function() {
//    timer.restart(true);
//  }
//});

//window.addEventListener("resize", resize);

//function animateSlides(direction) {
//  var progress = animation.progress() + direction / numSlides;
//  timer.pause();
//  animation.pause();
//  gsap.to(animation, {
//    duration: slideDuration,
//    progress: gsap.utils.snap(1 / numSlides, progress), 
//    overwrite: true,
//    modifiers:{
//      progress: wrapProgress // value => (value < 0 || value === 1 ? 1 : 0) + (value % 1)
//    },
//    onComplete:() => timer.restart(true)
//  });
//}

//function autoPlay() { 
//  animation.play();
//  gsap.fromTo(animation, {timeScale: 0}, {timeScale: 1, duration: 1, overwrite: true, ease: "power1.in"})
//}

//function updateProgress() {  
//  animation.progress(wrapProgress(gsap.getProperty(proxy, "x") / wrapWidth));
//}

//function resize() {
//  var progress = animation.progress();  
//  slideWidth = slides[0].offsetWidth;
//  wrapWidth = slideWidth * numSlides;

//  animation.progress(0).clear().to(slides, {
//    duration: 100,
//    xPercent: "+=" + (numSlides * 100),
//    ease: "none",
//    modifiers: {
//      xPercent: wrap
//    }
//  })
//  .to(proxy, {x: wrapWidth, duration: 100, ease: "none"}, 0)
//  .progress(progress);
//}

////Hamster(document.querySelector('.w-method')).wheel(function(event, delta, deltaX, deltaY) {
//////  event.preventDefault();
////  animateSlides(delta/30);
////});


//ScrollTrigger.observe({
//   target: '.w-method',         // can be any element (selector text is fine)
//   type: "wheel,touch",    // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
//   wheelSpeed: 0.2,
//   onChangeY: (self) => {
//      animateSlides(self.deltaY/50)
//      //console.log(self.deltaY, self.deltaY/50)
//   }
//   //onChange: (self) =>  {
//   //   console.log("velocity:", self.velocityX, self.velocityY, "delta:", self.deltaX, self.deltaY, "target element:", self.target, "last event:", self.event);
//   // }
//   //onDown: () => next(),
// });