import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from 'split-type';
gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

let boolsTextBox = document.getElementById("bools-text");

setTimeout(() => {
   new SplitType(boolsTextBox, {
      types: "lines, words, chars",
      tagName: "span"
   });

   const boolsLines = gsap.utils.toArray(".line", boolsTextBox);
   boolsLines.forEach((line, i) => {
      let delay = i + 1;
      if (delay < 10) {
         delay = "." + delay;
      }
      if (delay >= 10 && delay < 100) {
         delay = (delay + '')[0] + "." + (delay + '')[1];
      }
      line.style.animationDelay = delay + "s";
      line.style.opacity = 0;

      mm.add("(min-width: 480px)", () => {
         gsap.to(line, {
            animationPlayState: 'running',
            scrollTrigger: {
               trigger: boolsTextBox,
               //scroller: scroller,
               start: "top bottom",
               end: "bottom bottom",
            }
         })
      });
      mm.add("(max-width: 480px)", () => {
         gsap.to(line, {
            animationPlayState: 'running',
            scrollTrigger: {
               trigger: boolsTextBox,
               //scroller: scroller,
               start: "40% bottom",
               end: "bottom bottom",
               //markers: true
            }
         })
      });
   });
   //const boolsWords = gsap.utils.toArray(".word", boolsTextBox);
   //boolsWords.forEach((word, i) => {
   //   if (i = 0) {
   //      //let boolsWordsChar = word.getElementsByClassName('char');
   //      const boolsWordsChar = gsap.utils.toArray(".char", word);
   //      boolsWordsChar.forEach(function (char) {
   //         char.style.opacity = 1;
   //      });
   //   }
   //});
   let boolsTextArray = gsap.utils.toArray(boolsTextBox);
   boolsTextArray.forEach(function (text) {
      gsap.to("#bools-text .char", {
         opacity: 1,
         stagger: 0.05,
         scrollTrigger: {
            trigger: ".bools",
            scrub: true,
            start: "top center",
            end: "+45%",
            //immediateRender: false,
            //markers: true
         }
      });

   });


}, "800");