import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();

mm.add("(min-width: 480px)", () => {

   var timeout;
   var firstScreen = document.querySelector("#first-screen");

   firstScreen.addEventListener('mousemove', e => {
      if (timeout) clearTimeout(timeout);
      setTimeout(callParallax.bind(null, e), 100);
      //callParallax(e);
   });

   function callParallax(e) {
      parallaxIt(e, ".leaf-left", -7);
      parallaxIt(e, ".leaf-center svg", -8);
      parallaxIt(e, ".leafs", -10);
      parallaxIt(e, ".ball-smoke", -8);
      parallaxIt(e, ".ball-core-box", -12);
      parallaxIt(e, ".val", -14);
      parallaxIt(e, ".kat", 14);
   }

   function parallaxIt(e, target, movement) {
      var relX = e.pageX - firstScreen.offsetLeft;
      var relY = e.pageY - firstScreen.offsetTop;

      gsap.to(target, 1, {
         x: (relX - firstScreen.offsetWidth / 2) / firstScreen.offsetWidth * movement,
         y: (relY - firstScreen.offsetHeight / 2) / firstScreen.offsetHeight * movement,
         ease: "Power3.easeOut",
      })
   }


   ScrollTrigger.create({
      trigger: '.first-screen',
      onEnter: () => gsap.to('.ill-anim', {
         animationPlayState: 'running',
      }),
      onLeave: () => gsap.to('.ill-anim', {
         animationPlayState: 'paused',
      }),
      onLeaveBack: () => gsap.to('.ill-anim', {
         animationPlayState: 'paused',
      }),
      onEnterBack: () => gsap.to('.ill-anim', {
         animationPlayState: 'running',
      }),
   });
   ScrollTrigger.create({
      trigger: '.first-screen',
      onEnter: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
      onLeave: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
      onLeaveBack: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
      onEnterBack: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
   });
   ScrollTrigger.create({
      trigger: '.second-screen',
      onEnter: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
      onLeave: () => gsap.to('.kat svg', {
         animationPlayState: 'paused',
      }),
      onLeaveBack: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
      onEnterBack: () => gsap.to('.kat svg', {
         animationPlayState: 'running',
      }),
   });

});