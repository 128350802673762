import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.to(".reviews-head__heart", {
   scale: 1,
   duration: 3,
   ease: "power3.out",
   scrollTrigger: {
      trigger: '.reviews-head',
      //scroller: scroller,
      start: '110% bottom',
      end: '+=30%',
      //scrub: true,
      //markers: true,
   }
});