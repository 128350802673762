import gsap from "gsap";

let mm = gsap.matchMedia();

mm.add("(max-width: 480px)", () => {

   let groups = gsap.utils.toArray(".services-list-block");
   let menus = gsap.utils.toArray(".services-list-block__title");
   let menuToggles = groups.map(createAnimation);

   menus.forEach((menu) => {
      menu.addEventListener("click", () => toggleMenu(menu));
   });

   function toggleMenu(clickedMenu) {
      menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
   }

   function createAnimation(element) {
      let menu = element.querySelector(".services-list-block__title");
      let box = element.querySelector(".services-list-block__links");
      //let minusElement = element.querySelector(".accordion-minus");
      let plusElement = element.querySelector(".services-list-block__title-plus");

      gsap.set(box, {
         height: "auto",
         //display: "block"
      });

      let animation = gsap
         .timeline()
         .from(box, {
            height: 0,
            //display: "none",
            duration: 0.5,
            ease: "power1.inOut"
         })
         //.from(minusElement, {
         //   duration: 0.2,
         //   autoAlpha: 0,
         //   ease: "none"
         //}, 0)
         .to(plusElement, {
            rotate: 45,
            //ease: "none"
         }, 0)
         .reverse();

      return function (clickedMenu) {
         if (clickedMenu === menu) {
            animation.reversed(!animation.reversed());
         } else {
            animation.reverse();
         }
      };
   }

});