import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

ScrollTrigger.batch(".blog-grid__item", {
   once: true,
   onEnter: elements => {
      gsap.fromTo(elements, {
         autoAlpha: 0,
         y: 60,
         opacity: 0,
      }, {
         duration: 2,
         ease: "power4.easeOut",
         autoAlpha: 1,
         y: 0,
         opacity: 1,
         stagger: 0.30
      });
   },
});