import scroller from "./scroller";
//import Scrollbar from 'smooth-scrollbar';
//import ScrollbarPlg, {
//   ScrollbarPlugin
//} from 'smooth-scrollbar';
//import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Observer from "gsap/Observer";
import Draggable from "gsap/Draggable";
import {
   verticalLoop
} from "./vertical-loop";

gsap.registerPlugin(ScrollTrigger, Draggable, Observer);

let mm = gsap.matchMedia();
const vw = (coef) => window.innerWidth * (coef / 100);
//mm.add("(max-width: 480px)", () => {
//   const scroller = "";
//   return scroller;
//});


mm.add("(min-width: 480px)", () => {
   //const scroller = document.querySelector('.scroller');

   //class ModalPlugin extends ScrollbarPlugin {
   //   static pluginName = 'modal';

   //   static defaultOptions = {
   //      open: false,
   //   };

   //   transformDelta(delta) {
   //      return this.options.open ? {
   //         x: 0,
   //         y: 0
   //      } : delta;
   //   }
   //}
   //Scrollbar.use(ModalPlugin, /* OverscrollPlugin */ );

   //// Smooth scroll setup
   ////Scrollbar.use(OverscrollPlugin);
   //const bodyScrollBar = Scrollbar.init(scroller, {
   //   damping: 0.07,
   //   //alwaysShowTracks: false,
   //   delegateTo: document,
   //   //plugins: {
   //   //   overscroll: {
   //   //      damping: 0.1,
   //   //      maxOverscroll: 50,
   //   //   } | true,
   //   //},
   //});
   ////const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

   //bodyScrollBar.setPosition(0, 0);
   //bodyScrollBar.track.xAxis.element.remove();

   ////console.log(bodyScrollBar.offset);
   ////console.log(bodyScrollBar.scrollTop);

   //ScrollTrigger.scrollerProxy(".scroller", {
   //   scrollTop(value) {
   //      if (arguments.length) {
   //         bodyScrollBar.scrollTop = value;
   //      }
   //      return bodyScrollBar.scrollTop;
   //   }
   //});

   //bodyScrollBar.addListener(ScrollTrigger.update);
   //ScrollTrigger.defaults({
   //   scroller: scroller
   //});





   //setTimeout(function () {
   // horisontal 
   const horizontalSections = gsap.utils.toArray('section.horizontal');

   horizontalSections.forEach(function (sec, i) {

      var thisPinWrap = sec.querySelector('.pin-wrap');
      var thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

      var getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);



      let mica = gsap.fromTo(thisAnimWrap, {
         x: () => 0
      }, {
         x: () => getToValue(),
         ease: "none",
         scrollTrigger: {
            trigger: sec,
            //scroller: scroller,
            start: "top top",
            end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
            pin: thisPinWrap,
            invalidateOnRefresh: true,
            anticipatePin: 1,
            scrub: true,
            //markers: true
         }
      });

      var pinMoreProjects = document.querySelector('.pin-more-project');
      //  var caseItemBoxScaled = document.querySelector('.case-item-box-scaled');

      gsap.to(pinMoreProjects, {
         ease: "none",
         scrollTrigger: {
            //scroller: scroller,
            //containerAnimation: mica,
            trigger: pinMoreProjects,
            start: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
            end: "+=900",
            pin: pinMoreProjects,
            scrub: true,
            //  markers: true,
         }
      });


      gsap.utils.toArray('.case-item-box-gallery').forEach((container, i) => {
         let tl = verticalLoop(container.querySelectorAll(".case-item-box-gallery-left-item"), {
               paused: true,
               repeat: -1,
               center: true,
               speed: 0.8,
               draggable: true,
               paddingBottom: vw(2.083),
            }),
            clamp = gsap.utils.clamp(-4, 4),
            isOver, reversedOnPause;

         let observer = Observer.create({
            target: document.scrollingElement,
            type: "scroll,wheel",
            wheelSpeed: 0.5,
            tolerance: 14,
            onChangeY: self => {
               //tl.timeScale(clamp(self.velocityY * 0.003));
               //console.log(clamp(self.velocityY * 0.003));
               gsap.to(tl, {
                  timeScale: clamp(self.velocityY * 0.003),
                  duration: 0.5,
                  ease: "power1.out",
               });
            }
         });

         gsap.to(tl, {
            scrollTrigger: {
               trigger: ".pin-more-project",
               start: "left center",
               //end: "bottom top",
               containerAnimation: mica,
               //markers: true,
               onEnter: elements => {
                  tl.play();
                  observer.enable();
               },
               onLeave: elements => {
                  tl.pause();
                  observer.disable();
               },
               onEnterBack: elements => {
                  tl.play();
                  observer.enable();
               },
               onLeaveBack: elements => {
                  tl.pause();
                  observer.disable();
               },
               //onUpdate: function (self) {
               //   self.direction === -1 ? tl.timeScale(-1) : tl.timeScale(1);
               //}
            }
         });
         gsap.to(tl, {
            scrollTrigger: {
               trigger: ".services",
               start: "+=100vh",
               //markers: true,
               onEnter: elements => {
                  tl.pause();
                  observer.disable();
               },
               onLeaveBack: elements => {
                  tl.play();
                  observer.enable();
               },
            }
         });
      });
      gsap.utils.toArray('.case-item-box-gallery').forEach((container, i) => {
         let tl = verticalLoop(container.querySelectorAll(".case-item-box-gallery-right-item"), {
               paused: true,
               repeat: -1,
               center: true,
               speed: 0.6,
               draggable: true,
               paddingBottom: vw(2.083),
            }),
            clamp = gsap.utils.clamp(-6, 6),
            isOver, reversedOnPause;

         let observer = ScrollTrigger.observe({
            target: document.scrollingElement,
            type: "scroll,wheel",
            wheelSpeed: 0.5,
            tolerance: 14,
            onChangeY: self => {
               //console.log(clamp(self.velocityY * 0.003));
               gsap.to(tl, {
                  timeScale: clamp(self.velocityY * 0.003),
                  duration: 0.4,
                  ease: "power1.out",
               });
            }
         });
         observer.disable();
         gsap.to(tl, {
            scrollTrigger: {
               trigger: container,
               start: "left right",
               end: "+=125%",
               //scroller: scroller,
               containerAnimation: mica,
               //markers: true,
               onEnter: elements => {
                  tl.play();
                  observer.enable();
               },
               onLeave: elements => {
                  tl.pause();
                  observer.disable();
               },
               onEnterBack: elements => {
                  tl.play();
                  observer.enable();
               },
               onLeaveBack: elements => {
                  tl.pause();
                  observer.disable();
               },
               //onUpdate: function (self) {
               //   self.direction === -1 ? tl.timeScale(-1) : tl.timeScale(1);
               //}
            }
         });
         gsap.to(tl, {
            scrollTrigger: {
               trigger: ".services",
               start: "+=100vh",
               //markers: true,
               onEnter: elements => {
                  tl.pause();
                  observer.disable();
               },
               onLeaveBack: elements => {
                  tl.play();
                  observer.enable();
               },
            }
         });
      });


      const items = gsap.utils.toArray('.item', thisAnimWrap);
      items.forEach(function (item, i) {
         var ceseItemBox = item.querySelector('.case-item-box');
         var ceseItemBoxPin = item.querySelector('.case-item-box-pin');
         var ceseItemBoxText = item.querySelector('.case-item-box-text-anim');
         var moreProjects = item.querySelector('.more-projects');

         if (ceseItemBoxPin) {
            gsap.to(ceseItemBoxPin, {
               x: "+48.6vw",
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "center right",
                  end: "center center",
                  scrub: true,
                  //  markers: true,
               }
            });

         }
         if (ceseItemBoxText) {
            gsap.to(ceseItemBoxText, {
               opacity: 1,
               scale: 1,
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "center right",
                  end: "center center",
                  scrub: true,
                  //  markers: true,
               }
            });
         }
         const imgs = gsap.utils.toArray('.parallaxed-case-img', ceseItemBoxPin);

         imgs.forEach(function (img, i) {
            gsap.to(img, {
               //opacity: 1,
               scale: 1.060,
               //x:24,
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "center right",
                  end: "center center",
                  scrub: true,
                  //  markers: true,
               }
            });
         });

         if (moreProjects) {
            var caseItemBoxScaled = item.querySelector('.case-item-box-scaled');
            gsap.to([moreProjects, caseItemBoxScaled], {
               x: "97vw",
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "-35vw left",
                  end: "right",
                  scrub: true,
                  //markers: true,
               }
            });
            gsap.set(moreProjects, {
               borderRadius: "9.027vw",
            });
            gsap.to(moreProjects, {
               height: "+=10%",
               borderRadius: "1.472vw",
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "-4.583vw left",
                  end: "+=300",
                  scrub: true,
                  //  markers: true,
               }
            });
            gsap.set(caseItemBoxScaled, {
               borderRadius: "9.027vw",
            });
            gsap.to(caseItemBoxScaled, {
               width: window.innerWidth,
               height: "+=10%",
               left: 0,
               borderRadius: "3.472vw",
               ease: "none",
               scrollTrigger: {
                  containerAnimation: mica,
                  trigger: ceseItemBox,
                  start: "-2.583vw left",
                  end: "+=300",
                  scrub: true,
                  //  markers: true,
               }
            });
         }


         //bg
         var bgColor = item.getAttribute('data-bgcolor');
         gsap.to('.bg', {
            background: bgColor,
            scrollTrigger: {
               containerAnimation: mica,
               trigger: ceseItemBox,
               start: "center right",
               end: '+=300',
               //end: "right right",
               scrub: true,
               //markers: true,
            }
         });
         //bg end

         ScrollTrigger.create({
            trigger: '.horizontal .dark-bg',
            containerAnimation: mica,
            start: "center right",
            end: '+=300',
            scrub: true,

            onEnter: () => gsap.to('.menu-btn-link-icon', {
               fill: "#fff"
            }),
            onLeave: () => gsap.to('.menu-btn-link-icon', {
               fill: "#fff"
            }),
            onLeaveBack: () => gsap.to('.menu-btn-link-icon', {
               fill: "#121221"
            }),
            onEnterBack: () => gsap.to('.menu-btn-link-icon', {
               fill: "#fff"
            }),
         });

      });
   });

   // This part is only necessary if you're using ScrollTrigger's markers:
   //if (document.querySelector('.gsap-marker-scroller-start')) {
   //   const markers = gsap.utils.toArray('[class *= "gsap-marker"]');
   //   bodyScrollBar.addListener(({
   //      offset
   //   }) => gsap.set(markers, {
   //      marginTop: -offset.y
   //   }));
   //}
   // End section necessary only if you're using ScrollTrigger's markers



   //lock scroll menu open

   //var menuBurg = document.querySelector(".menu-btn-link");
   //let menuBurgerStat = "close";

   //menuBurg.onclick = function () {
   //   if (menuBurgerStat == "close") {
   //      bodyScrollBar.updatePluginOptions('modal', {
   //         open: true
   //      });
   //      menuBurgerStat = "open";
   //   } else {
   //      bodyScrollBar.updatePluginOptions('modal', {
   //         open: false
   //      });
   //      menuBurgerStat = "close";
   //   }
   //};
   //return scroller;
});

//let scroller = mm.scroller
//export default scroller



//FOOTER FOOTER FOOTER FOOTER 
mm.add("(min-width: 480px)", () => {
   gsap.set('.footer__box', {
      yPercent: -50
   })

   const uncover2 = gsap.timeline({
      paused: true
   })

   uncover2.to('.footer__box', {
      yPercent: 0,
      ease: 'none'
   });

   ScrollTrigger.create({
      trigger: '.cta',
      //scroller: scroller,
      start: 'bottom bottom',
      end: '+=100%',
      animation: uncover2,
      scrub: true,
      //markers: true,
   });


   gsap.set('.footer__head-logo', {
      scale: 1.2,
      yPercent: 100,
   })
   gsap.to('.footer__head-logo', {
      scale: 1,
      yPercent: 0,
      scrollTrigger: {
         trigger: '.cta',
         //scroller: scroller,
         start: 'bottom bottom',
         end: '+=100%',
         scrub: true,
         //markers: true,
      }
   })


   gsap.set(".social-grid__item", {
      autoAlpha: 0,
      y: 60,
      opacity: 0,
   });

   ScrollTrigger.batch(".social-grid__item", {
      once: true,
      onEnter: elements => {
         gsap.fromTo(elements, {
            autoAlpha: 0,
            y: 60,
            opacity: 0,
         }, {
            autoAlpha: 1,
            y: 0,
            opacity: 1,
            //ease: "power3.out",
            duration: 2,
            ease: "power4.easeOut",
            stagger: 0.30
         });
      },
   });
});


gsap.set(".footer__show", {
   autoAlpha: 0,
   y: 30,
   opacity: 0,
});
ScrollTrigger.batch(".footer__show", {
   once: true,
   onEnter: elements => {
      gsap.fromTo(elements, {
         autoAlpha: 0,
         y: 30,
         opacity: 0,
      }, {
         ease: "power3.out",
         duration: 2,
         autoAlpha: 1,
         y: 0,
         opacity: 1,
         //markers: true,
         //scroller: scroller,
         //stagger: 0.15
         stagger: {
            //from: "center",
            //ease: "power3.out",
            amount: 0.60
         }
      });
   },
});

//END FOOTER END FOOTER END FOOTER END FOOTER 